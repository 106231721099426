/**
 * All code related to the header
 */
export default function () {
    /**
     * Initiliaze the code
     */
    setDataPrograms();

    function setDataPrograms () {
        const defaultImages = document.querySelectorAll('.tabs-trigger .menu-image:not(.tab) img');
        const dataProgramTriggers = document.querySelectorAll('[data-program]');

        // Changes the src and srcset of the image depending on which tab is active
        dataProgramTriggers.forEach(dataProgramTrigger => {
            window.addEventListener('load', function (e) {
                const dataProgram = document.querySelector('[data-program].active').getAttribute('data-program');
                const currentProgramTabs = document.querySelectorAll('.tab [href="#' + dataProgram + '"]');

                currentProgramTabs.forEach(currentProgramTab => {
                    defaultImages.forEach(defaultImage => {
                        // Set the label
                        const defaultLabel = defaultImage.closest('.container-image').querySelector('.menu-item-card-label');
                        const currentProgramHref = currentProgramTab.getAttribute('href');
                        const CurrentDataProgram = document.querySelector('.tabs-container [data-program="' + currentProgramHref.replace('#', '') + '"]');
                        defaultLabel.innerHTML = CurrentDataProgram.textContent;

                        // Set the link
                        const defaultLink = defaultImage.closest('.menu-item-card');
                        const currentTabLink = currentProgramTab.closest('.tab').querySelector('.archive-link a').getAttribute('href');
                        defaultLink.href = currentTabLink;

                        // Set the image
                        const currentTabImage = document.querySelector('[data-programme-title="' + currentProgramTab.textContent.replace(/\s+$/, '').replace('-', '') + '"]');
                        const currentTabImageSrc = currentTabImage.getAttribute('data-programme-image');
                        defaultImage.src = currentTabImageSrc;
                        defaultImage.srcset = currentTabImageSrc;
                        defaultImage.setAttribute('data-srcset', currentTabImageSrc);
                        defaultImage.setAttribute('data-src', currentTabImageSrc);
                    });
                });
            });

            dataProgramTrigger.addEventListener('click', function (e) {
                const dataProgram = e.currentTarget.getAttribute('data-program');
                const currentProgramTabs = document.querySelectorAll('.tab [href="#' + dataProgram + '"]');

                currentProgramTabs.forEach(currentProgramTab => {
                    defaultImages.forEach(defaultImage => {
                        // Set the label
                        const defaultLabel = defaultImage.closest('.container-image').querySelector('.menu-item-card-label');
                        const currentProgramHref = currentProgramTab.getAttribute('href');
                        const CurrentDataProgram = document.querySelector('.tabs-container [data-program="' + currentProgramHref.replace('#', '') + '"]');
                        defaultLabel.innerHTML = CurrentDataProgram.textContent;

                        // Set the link
                        const defaultLink = defaultImage.closest('.menu-item-card');
                        const currentTabLink = currentProgramTab.closest('.tab').querySelector('.archive-link a').getAttribute('href');
                        defaultLink.href = currentTabLink;

                        // Set the image
                        const currentTabImage = document.querySelector('[data-programme-title="' + currentProgramTab.textContent.replace(/\s+$/, '').replace('-', '') + '"]');
                        const currentTabImageSrc = currentTabImage.getAttribute('data-programme-image');
                        defaultImage.src = currentTabImageSrc;
                        defaultImage.srcset = currentTabImageSrc;
                        defaultImage.setAttribute('data-srcset', currentTabImageSrc);
                        defaultImage.setAttribute('data-src', currentTabImageSrc);
                    });
                });
            });
        });
    }
}
