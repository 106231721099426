/**
 * Copy page link
 */

export default function () {
    function clipboard () {
        const buttons = document.querySelectorAll('.clipboard-copy');

        function toggleVisibilityTooltip () {
            const tooltip = document.querySelector('.tooltip');

            tooltip.classList.add('visible');
            setTimeout(() => {
                tooltip.classList.remove('visible');
            }, 1000);
        }

        if (buttons.length) {
            buttons.forEach(button => {
                const copy = button.querySelector('#copy');
                button.addEventListener('click', function (e) {
                    e.preventDefault();
                    toggleVisibilityTooltip();
                    navigator.clipboard.writeText(copy.textContent);
                });
            });
        }
    }

    clipboard();
}

;
