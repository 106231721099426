import Swiper, { Navigation, Pagination } from 'swiper';

export default function() {
    if (document.querySelector('.carousel-testimonials')) {
        Swiper.use([Navigation, Pagination]);

        const state = {
            swiper: null,
        };

        const initSwiper = () => {
            const swiperName = '.swiper-testimonials';
            const nextName = '.carousel__next-testimonials';
            const prevName = '.carousel__prev-testimonials';

            state.swiper = new Swiper(swiperName, {
                loop: false,
                slidesPerView: 1,
                spaceBetween: 24,
                allowTouchMove: true,
                pagination: {
                    el: '.swiper-pagination',
                    type: 'progressbar',
                },
                navigation: {
                    nextEl: nextName,
                    prevEl: prevName,
                },
                breakpoints: {
                    1024: {
                        slidesPerView: 1.5,
                    },
                },
            });
        };

        initSwiper();
    }
}
