/**
 * Tabs module file.
 */
export default function () {
    const filterItems = document.querySelectorAll(".calendar__filter-item");
    const sessions = document.querySelectorAll("[data-session]");

    if (filterItems.length && sessions.length) {
        filterItems[0].classList.add("active");

        sessions.forEach((section) => {
            section.classList.add("hidden");
        });
        sessions[0].classList.remove("hidden");

        filterItems.forEach((filterItem) => {
            filterItem.addEventListener("click", function (e) {
                filterItems.forEach((item) => {
                    item.classList.remove("active");
                });

                const selectedFilter = filterItem.getAttribute("data-session-filter");
                filterItem.classList.add("active");

                sessions.forEach((session) => {
                    session.classList.add("hidden");

                    if (selectedFilter === session.getAttribute("data-session")) {
                        session.classList.remove("hidden");
                    }
                });
            });
        });
    }
}
