import academicPathGrid from './modules/academic-path-grid';
import accordeon from './modules/accordeon';
import byProgramCarousel from './modules/by-program-carousel';
import findYourProgramCarousel from './modules/find-your-program';
import comparativeTabsCarousel from './modules/comparative-tabs-carousel';
import setTabsHeight from './modules/set-tabs-height';
import tabs from './modules/tabs';
import courseTabs from './modules/course-tabs';
import calendar from './modules/calendar';
import jobsCarousel from './modules/jobs-carousel';
import blogCarousel from './modules/blog-carousel.js';
import testimonialCarousel from './modules/testimonial-carousel.js';
import toWatchCarousel from './modules/to-watch-carousel.js';
import testimonyCarousel from './modules/testimony-carousel';
import textImageCarousel from './modules/text-image-carousel';
import copy from './modules/copy';
import faq from './modules/faq';
import fileUpload from './components/file-upload';
import filters from './modules/filters';
import header from './components/header';
import menuImage from './components/menu-image';
import loadMoreAndFilters from './modules/load-more-and-filters';
import marquee from './modules/marquee';
import menuTabs from './components/menu-tabs';
import alertBar from './components/alert-bar';
import parallax from './modules/parallax';
import videoGallerySwiper from './modules/video-gallery-swiper';
import internshipAccordion from './modules/internship-accordion.js';
import tippy from './components/tippy.js';

document.addEventListener('DOMContentLoaded', function () {
    window.$ = window.jQuery; // Quick fix for some plugins that rely on jQuery
    window.ignoreScrollEvent = false;
    header();
    alertBar();
    byProgramCarousel();
    findYourProgramCarousel();
    comparativeTabsCarousel();
    setTabsHeight();
    calendar();
    accordeon();
    blogCarousel();
    testimonialCarousel();
    internshipAccordion();
    toWatchCarousel();
    jobsCarousel();
    textImageCarousel();
    testimonyCarousel();
    copy();
    faq();
    fileUpload();
    filters();
    loadMoreAndFilters();
    marquee();
    menuTabs();
    parallax();
    tabs();
    courseTabs();
    academicPathGrid();
    menuImage();
    videoGallerySwiper();
    tippy();
});
