import header from './header';

/**
 * All code related to the menu tabs
 *
 * For tabs to work properly, the href of your menu item with the class '.tab',
 * needs to be an '#', followed by its ID. EX: #preuniveristy
 *
 * The parent of your menu items (at the root of your menu)
 * also needs to have the class '.tabs-trigger'
 */
export default function () {
    /**
     * Initiliaze the code
     */
    if (window.innerWidth > 1024) {
        initProgramTabs();
        toggleTabActive();
        appendTabsContainer();
        toggleTabContainerVisibility();
    }

    function initProgramTabs() {
        const tabs = document.querySelectorAll('#masthead .menu-item-container > .menu .tab');
        const tabsContainerFloat = document.querySelector('#tabs-container--float');
        const tabsContainerTop = document.querySelector('#tabs-container--top');

        tabs.forEach((tab) => {
            const tabName = tab.querySelector('a').textContent;
            const tabData = tab.querySelector('a').getAttribute('href').replace('#', '');
            const span = document.createElement('span');

            span.setAttribute('data-program', tabData);
            tabsContainerFloat.appendChild(span).append(tabName);
            tabsContainerTop.appendChild(span.cloneNode()).append(tabName);
        });
        const seeAllTag = document.createElement('a');
        const location = window.location.origin + '/nos-programmes';

        seeAllTag.setAttribute('href', location);
        seeAllTag.innerHTML = 'Voir tout';

        tabsContainerFloat.appendChild(seeAllTag);
        tabsContainerTop.appendChild(seeAllTag);
    }

    function toggleTabActive() {
        const tabsContainers = document.querySelectorAll('.tabs-container');
        tabsContainers.forEach((tabsContainer) => {
            const tabs = tabsContainer.querySelectorAll('span');
            const defaultTab = tabsContainer.querySelector('span');
            const defaultTabData = defaultTab.getAttribute('data-program');

            // Sets first program of .tabs-container to 'active'
            const header = defaultTab.closest('.site__header');
            const subMenuToActivate = header.querySelector('[href*="#' + defaultTabData + '"]').closest('.tab');

            defaultTab.classList.add('active');
            subMenuToActivate.classList.add('sub-menu-visible');

            // Toggle program
            tabs.forEach((tab) => {
                tab.addEventListener('click', function (e) {
                    const currentTab = e.currentTarget;
                    const currentTabData = currentTab.getAttribute('data-program');
                    const tabsWithCurrentData = document.querySelectorAll('.site__header [data-program="' + currentTabData + '"]');
                    const activatedTabs = document.querySelectorAll('.site__header span.active');
                    const subMenus = header.querySelectorAll('.tab');

                    // Clears all the tabs with the class 'active'
                    activatedTabs.forEach((activatedTab) => {
                        activatedTab.classList.remove('active');
                    });
                    subMenus.forEach((subMenu) => {
                        subMenu.classList.remove('sub-menu-visible');
                    });

                    // Set the clicked tab to active in both headers
                    tabsWithCurrentData.forEach((targetedTab) => {
                        const subMenuToActivate = header.querySelector('[href*="#' + currentTabData + '"]').closest('.tab');

                        targetedTab.classList.toggle('active');
                        subMenuToActivate.classList.add('sub-menu-visible');
                    });
                });
            });
        });
    }

    function appendTabsContainer() {
        const tabsContainers = document.querySelectorAll('.tabs-container');

        tabsContainers.forEach((tabsContainer) => {
            const tabsTrigger = tabsContainer.closest('.menu-item-container').querySelector('.tabs-trigger');
            tabsTrigger.appendChild(tabsContainer);
        });
    }

    function toggleTabContainerVisibility() {
        const tabsTriggers = document.querySelectorAll('.tabs-trigger');
        tabsTriggers.forEach((tabsTrigger) => {
            const tabsContainer = tabsTrigger.closest('.menu-item-container').querySelector('.tabs-container');
            const headers = document.querySelectorAll('.site__header');

            tabsTrigger.addEventListener('mouseenter', () => {
                tabsContainer.classList.add('tabs-container--active');
                headers.forEach((header) => {
                    header.classList.add('tabs-active');
                });
            });
            tabsTrigger.addEventListener('mouseleave', () => {
                tabsContainer.classList.remove('tabs-container--active');
                headers.forEach((header) => {
                    header.classList.remove('tabs-active');
                });
            });
        });
    }
}
