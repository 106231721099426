import Swiper, {
    Navigation,
    Thumbs,
    Controller,
    EffectFade
}
    from 'swiper';

export default function () {
    Swiper.use([Navigation, Thumbs, Controller, EffectFade]);

    const initSwiper = () => {
        const nextName = '.carousel__next';
        const prevName = '.carousel__prev';

        const swiperImage = new Swiper('.swiper-testimony__big-image', {
            loop: true,
            effect: 'fade',
            allowTouchMove: true
        });
        const swiperTestimony = new Swiper('.swiper-testimony', {
            autoHeight: true, // enable auto height
            loop: true,
            effect: 'fade',
            allowTouchMove: true,
            navigation: {
                nextEl: nextName,
                prevEl: prevName
            }
        });

        swiperImage.controller.control = swiperTestimony;
        swiperTestimony.controller.control = swiperImage;
    };

    if (document.querySelector('.testimony')) {
        initSwiper();
    }
}
