import Swiper, { Navigation, Pagination } from 'swiper';

export default function () {
    Swiper.use([Navigation]);
    Swiper.use([Pagination]);

    const state = {
        swiper: null
    };

    const initSwiper = (slug) => {
        const swiperName = '.swiper-find-your-program';
        const nextName = '.carousel__next';
        const prevName = '.carousel__prev';
        state.swiper = new Swiper(swiperName, {
            loop: false,
            allowTouchMove: true,
            spaceBetween: 24,
            slidesPerView: 'auto',
            slidesPerGroup: 3,
            pagination: {
                el: '.swiper-pagination',
                type: 'progressbar'
            },
            navigation: {
                nextEl: nextName,
                prevEl: prevName
            },
            breakpoints: {
                // when window width is >= 1280px
                1280: {
                    slidesPerView: 3,
                    slidesPerGroup: 3
                },
                // when window width is >= 640px
                641: {
                    slidesPerGroup: 2,
                    slidesPerView: 2
                },
                // when window width is >= 320px
                320: {
                    slidesPerGroup: 1,
                    slidesPerView: 1
                }
            }
        });
    };

    if (document.querySelector('.find-your-program')) {
        initSwiper();
    }
}
