import {
    gsap
}
from 'gsap';
import {
    ScrollTrigger
}
from 'gsap/ScrollTrigger';

export default function () {
    const tabs = document.querySelectorAll('[data-anchor-ids]');

    gsap.registerPlugin(ScrollTrigger);

    gsap.utils.toArray('section').forEach((section, i) => {
        const parent = section.querySelectorAll('.parallax-container');

        parent.forEach((parent, i) => {
            const parallax = parent.querySelector('.parallax-container-image');

            if (parallax != null) {
                gsap.to(parallax, {
                    scrollTrigger: {
                        trigger: parent,
                        start: 'top bottom',
                        end: 'bottom top',
                        scrub: true
                    },
                    y: function (i, target) {
                        return '10%'; // scale the image by 1.25
                    },
                    ease: 'none'
                });
            }
        });

        const reverseParent = section.querySelectorAll('.parallax-container-reverse');
        reverseParent.forEach((parent, i) => {
            const parallax = parent.querySelector('.parallax-container-image');

            if (parallax != null) {
                gsap.to(parallax, {
                    scrollTrigger: {
                        trigger: parent,
                        start: 'top bottom',
                        end: 'bottom top',
                        scrub: true
                    },
                    y: function (i, target) {
                        return '-10%'; // scale the image by 1.25
                    },
                    ease: 'none'
                });
            }
        });
    });

    if (tabs.length) {
        tabs.forEach(tab => {
            tab.addEventListener('click', function (e) {
                setTimeout(() => {
                    ScrollTrigger.refresh();
                }, 150);
            });
        });
    }
}
