import { gsap } from 'gsap';

export default function () {
    const sessions = document.querySelectorAll('.session_container');
    sessions.forEach((session, index) => {
        if (index !== 0) {
            session.style.display = 'none';
        }
    });
    const selectorItems = document.querySelectorAll('.selector_item');
    selectorItems.forEach((item, key) => {
        const input = item.querySelector('input');
        if(key !== 0) {
            input.removeAttribute('checked');
        }
        item.addEventListener('click', function () {
            input.checked = true;
            const selectedPathway = input.getAttribute('data-pathway');
                
            sessions.forEach(session => {
                session.style.display = 'none';
            });

            const selectedSession = document.querySelector(`.session_container[data-session="${selectedPathway}"]`);
            if (selectedSession) {
                selectedSession.style.display = 'flex';
            }
        });
    });

    const sessionCards = document.querySelectorAll('.course-grid .accordeon-grid-card');

    if (sessionCards && sessionCards.length > 0) {
        sessionCards.forEach((card) => {
            const question = card.querySelector('.accordeon__question');
            question.addEventListener('click', function (e) {
                e.preventDefault();
                const currentCard = e.currentTarget.closest('.accordeon-grid-card');
                const answer = currentCard.querySelector('.accordeon__answer');

                if (currentCard.classList.toggle('accordeon--active')) {
                    gsap.to(answer, {
                        height: 'auto',
                        duration: 0.4
                    });
                } else {
                    gsap.to(answer, {
                        height: 0,
                        duration: 0.4
                    });
                }
            });
        });
    }
}