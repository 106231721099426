import Swiper, { Navigation } from 'swiper';

export default function () {
    if (document.querySelector('.by-programs')) {
        Swiper.use([Navigation]);

        const state = {
            swiper: null
        };

        const initSwiper = (slug) => {
            const swiperName = '.swiper-program-' + slug;
            const nextName = '.carousel__next-' + slug;
            const prevName = '.carousel__prev-' + slug;
            state.swiper = new Swiper(swiperName, {
                loop: false,
                spaceBetween: 24,
                allowTouchMove: true,
                navigation: {
                    nextEl: nextName,
                    prevEl: prevName
                },
                breakpoints: {
                    // when window width is >= 320px
                    320: {
                        slidesPerView: 1
                    },
                    // when window width is >= 640px
                    640: {
                        slidesPerView: 2
                    },
                    // when window width is >= 800px
                    800: {
                        slidesPerView: 'auto'
                    }
                }
            });
        };

        const swipers = document.getElementsByClassName('carousel');

        for (let i = 0; i < swipers.length; i++) {
            const slug = swipers[i].getAttribute('data-slug');
            initSwiper(slug);
        }
    }
}
