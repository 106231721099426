/**
 * Faq module file.
 */
export default function () {
    const navItems = document.querySelectorAll(".faq__nav-item");
    const sacConfig = {
        threshold: [0.1, 0.5, 0.8],
        ratio: 0.8,
    };

    function initScrollAwareNavigation() {
        const menu = document.getElementById("scroll-aware-navigation");
        const content = document.getElementById("scroll-aware-content");

        if (!menu || !content) {
            return;
        }

        setupIntersectionObserver(content);
    }

    if (navItems.length) {
        navItems.forEach((navItem) => {
            navItem.addEventListener("click", function (e) {
                e.preventDefault();
                const targetElement = document.getElementById(navItem.dataset.faqLink);

                if (targetElement) {
                    const targetOffset = targetElement.getBoundingClientRect().top + window.pageYOffset;
                    const scrollToOptions = {
                        top: targetOffset - 300,
                        behavior: "smooth",
                    };
                    window.scrollTo(scrollToOptions);
                }
            });
        });
    }

    function setupIntersectionObserver(content) {
        const sections = content.querySelectorAll(".scroll-aware-section");
        const observerOptions = {
            root: null,
            rootMargin: content.dataset.rootMargin || "0px",
            threshold: sacConfig.threshold,
        };
        const observer = new IntersectionObserver(observerCallback, observerOptions);

        for (let i = 0; i < sections.length; i++) {
            observer.observe(sections[i]);
        }
    }

    function observerCallback(entries) {
        entries.forEach((entry) => {
            if (entry.target.id.length > 0) {
                const navItem = document.querySelector(`.scroll-aware-link[href="#${entry.target.id}"]`);

                if (entry.isIntersecting) {
                    const ratio = sacConfig.ratio;
                    const targetBiggerThanViewport = entry.intersectionRatio < ratio && entry.target.offsetHeight > window.innerHeight;
                    const targetFullyInViewport = entry.intersectionRatio >= ratio;

                    if (targetBiggerThanViewport || targetFullyInViewport) {
                        const activeItems = document.querySelectorAll(".scroll-aware-link--active");

                        for (let i = 0; i < activeItems.length; i++) {
                            activeItems[i].classList.remove("scroll-aware-link--active");
                        }

                        navItem.classList.add("scroll-aware-link--active");
                    }
                }
            }
        });
    }

    if (window.innerWidth > 1024) {
        initScrollAwareNavigation();
    }
}
