/* eslint-disable indent */
import ScrollLock from '../utils/scroll-lock';
import { gsap } from 'gsap';

/**
 * All code related to the header
 */
export default function () {
    /**
     * Initiliaze the code
     */
    setMenuCardWidth();
    adjustHeaderHeight();
    headerVisibilityToggler();
    headerVisibilityWithFancybox();
    menuBurgerToggler();
    toggleSearch();

    if (window.innerWidth > 1024) {
        subMenuVisibilityToggler();
    } else {
        subMenuVisibilityTogglerMobile();
    }

    const screenLock = new ScrollLock();
    const siteContent = document.querySelector('.site__content');

    function setMenuCardWidth() {
        const menuCard = document.querySelectorAll('.menu-item-card');

        menuCard.forEach((card) => {
            if (window.innerWidth > 1024) {
                const cardParent = card.closest('li');
                cardParent.classList.add('menu-image', 'col-4');
            } else {
                const cardParent = card.closest('li');
                cardParent.classList.add('menu-image', 'col-12');
            }
        });
    }

    /**
     * Adjust the header height styling on the body for layout and style purposes
     */
    let resizetimer = null;

    function adjustHeaderHeight() {
        const header = document.querySelector('.site__header:not(.site__header--float)');
        document.body.style.setProperty('--header-h', header.offsetHeight + 'px');
    }

    window.addEventListener('resize', () => {
        clearTimeout(resizetimer);
        resizetimer = window.setTimeout(() => {
            adjustHeaderHeight();
        }, 250);
    });

    /**
     * Hide the nav when Fancybox is active
     */
    function headerVisibilityWithFancybox() {
        const header = document.querySelector('.site__header');
        const body = document.querySelector('body');
        const fancyboxes = document.querySelectorAll('[data-fancybox]');

        fancyboxes.forEach((fancybox) => {
            window.addEventListener('click', function () {
                if (body.classList.contains('fancybox-active')) {
                    header.classList.add('nav-up');
                    header.classList.remove('nav-down');
                }
            });
        });
    }

    /**
     * Show or hide the floating nav when scrolling
     */
    function headerVisibilityToggler() {
        const header = document.querySelector('#masthead');
        const firstSection = document.querySelector('#main section');
        let headerDistance = 400;
        let lastScrollY = window.scrollY;

        if (firstSection) {
            // Set header distance to firstSection height if it's bigger than the default distance.
            headerDistance = firstSection.offsetHeight < headerDistance ? firstSection.offsetHeight : headerDistance;
        }

        function toggleNav() {
            const currentScrollY = window.scrollY;
            const isAtTopPage = currentScrollY < headerDistance;

            if (currentScrollY > lastScrollY && currentScrollY > 100) {
                header.classList.add('nav-up');
                header.classList.remove('nav-down');
            } else if (currentScrollY < lastScrollY && currentScrollY > 100) {
                // Scrolling up: add nav-down and remove nav-up class
                header.classList.add('nav-down');
                header.classList.remove('nav-up');
            }

            // Handle when user is at the top of the page
            isAtTopPage && !document.body.classList.contains('menu--opened') ? document.body.classList.add('reached-top') : document.body.classList.remove('reached-top');

            // Update lastScrollY to the current scroll position
            lastScrollY = currentScrollY;
        }

        window.addEventListener('scroll', toggleNav);
        window.addEventListener('load', toggleNav);
    }

    function subMenuVisibilityToggler() {
        const parentMenus = document.querySelectorAll('[id*="menu-primary"] > .menu-item-has-children');
        const topbarsSearch = document.querySelectorAll('.topbar__search-panel');

        parentMenus.forEach((menu) => {
            const currentHeader = menu.closest('.site__header');

            menu.addEventListener('mouseenter', () => {
                currentHeader.classList.add('site__header--sub-menu-open');
                topbarsSearch.forEach((topbarSearch) => {
                    topbarSearch.classList.add('topbar__search-panel--hidden');
                });

                siteContent.classList.add('sub-menu-opened');
                menu.addEventListener('mouseleave', closeSubmenu.bind(null, [currentHeader, topbarsSearch, siteContent]), {
                    once: true,
                });
            });
        });
    }

    function closeSubmenu(menuElements) {
        const [currentHeader, topbarsSearch, siteContent] = menuElements;

        siteContent.classList.remove('sub-menu-opened');
        currentHeader.classList.remove('site__header--sub-menu-open');
        topbarsSearch.forEach((topbarSearch) => {
            topbarSearch.classList.remove('topbar__search-panel--hidden');
        });
    }

    function subMenuVisibilityTogglerMobile() {
        const subMenusTrigger = document.querySelectorAll('.menu-item-has-children:not(.footer__menu-title) a');

        subMenusTrigger.forEach((subMenuTrigger) => {
            subMenuTrigger.addEventListener('click', function (e) {
                const currentSubMenu = e.currentTarget.closest('.menu-item-has-children');
                const subMenuElements = currentSubMenu.querySelector('.sub-menu');

                if (currentSubMenu.classList.toggle('sub-menu--active')) {
                    gsap.to(subMenuElements, {
                        height: 'auto',
                        duration: 0.4,
                    });
                } else {
                    gsap.to(subMenuElements, {
                        height: 0,
                        duration: 0.4,
                    });
                }
            });
        });
    }

    /**
     * Toggle the mobile menu
     */
    function menuBurgerToggler() {
        const menuTogglers = document.querySelectorAll('.hamburger-menu__button');

        for (let i = 0; i < menuTogglers.length; i++) {
            menuTogglers[i].addEventListener('click', (e) => {
                const target = e.currentTarget;

                document.body.classList.toggle('menu--opened');
                target.classList.toggle('hamburger-menu__button--active');
                target.closest('.site__header').classList.toggle('site__header--opened');

                if (target.classList.contains('hamburger-menu__button--active')) {
                    const topbarsSearch = document.querySelectorAll('.topbar__search-panel');
                    topbarsSearch.forEach((topbarSearch) => {
                        topbarSearch.classList.remove('topbar__search-panel--active');
                    });
                    window.ignoreScrollEvent = true;
                    screenLock.lock();
                } else {
                    screenLock.unlock();

                    window.setTimeout(() => {
                        window.ignoreScrollEvent = false;
                    }, 500);
                }
            });
        }
    }

    function focusSearchInput(input) {
        input.forEach((input) => {
            input.focus();
        });
    }

    /**
     * Toggle search
     */
    function toggleSearch() {
        const searchButtons = document.querySelectorAll('.topbar__search-button');
        const topbarsSearch = document.querySelectorAll('.topbar__search-panel');
        const siteHeaderTop = document.querySelector('.site__header--top');
        const closeButtons = document.querySelectorAll('.topbar__search-close');
        const inputSearch = document.querySelectorAll('.search-form__field');

        searchButtons.forEach((searchButton) => {
            searchButton.addEventListener('click', (e) => {
                siteHeaderTop.classList.toggle('site__header--search-open');
                siteContent.classList.toggle('sub-menu-opened');
                topbarsSearch.forEach((topbarSearch) => {
                    topbarSearch.classList.toggle('topbar__search-panel--active');
                });
                focusSearchInput(inputSearch);
            });
        });

        closeButtons.forEach((closeButton) => {
            closeButton.addEventListener('click', (e) => {
                siteHeaderTop.classList.remove('site__header--search-open');
                siteContent.classList.remove('sub-menu-opened');
                topbarsSearch.forEach((topbarSearch) => {
                    topbarSearch.classList.remove('topbar__search-panel--active');
                });
            });
        });
    }
}
