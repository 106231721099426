/* eslint-disable indent */
import { gsap } from 'gsap';

export default function () {
    const academicPaths = document.querySelectorAll('.academic-path-grid');
    const isMobile = window.matchMedia('(max-width: 1280px)').matches;

    if (academicPaths.length) {
        const sessionButtons = document.querySelectorAll('.academic-path-grid__button');
        const sessionContainers = document.querySelectorAll('.academic-path-grid__description');

        const sessionButtonsMobile = document.querySelectorAll('.academic-path-grid__button.mobile');

        if (isMobile) {
            sessionButtonsMobile.forEach((sessionMobileButton) => {
                sessionMobileButton.addEventListener('click', function (e) {
                    const session = e.target.getAttribute('data-session');
                    toggleActiveSession(session, e.target);
                });
            });
        } else {
            sessionButtons.forEach((sessionButton) => {
                sessionButton.addEventListener('click', function (e) {
                    const session = e.target.getAttribute('data-session');
                    toggleActiveSession(session, e.target);
                });
            });
        }

        function toggleActiveSession(session, element) {
            const isActive = element.classList.contains('active');

            sessionButtons.forEach((button) => {
                button.classList.remove('active');
            });

            element.classList.toggle('active', !isActive);

            sessionContainers.forEach((sessionContainer) => {
                const container = sessionContainer.getAttribute('data-session');

                if (session === container) {
                    if (!isActive) {
                        gsap.to(sessionContainer, {
                            opacity: 1,
                            duration: 0.4,
                            ease: 'expo.out',
                            onComplete() {
                                sessionContainer.classList.remove('hidden');
                            },
                        });
                    } else {
                        gsap.to(sessionContainer, {
                            opacity: 0,
                            duration: 0.4,
                            ease: 'expo.out',
                            onComplete() {
                                sessionContainer.classList.add('hidden');
                            },
                        });
                    }
                } else {
                    gsap.to(sessionContainer, {
                        opacity: 0,
                        duration: 0.4,
                        ease: 'expo.out',
                        onComplete() {
                            sessionContainer.classList.add('hidden');
                        },
                    });
                }
            });
        }
    }
}
