import Swiper, { Navigation, Pagination } from 'swiper';

export default function () {
    if (document.querySelector('.carousel-jobs')) {
        Swiper.use([Navigation, Pagination]);

        const state = {
            swiper: null
        };

        const initSwiper = () => {
            const swiperName = '.swiper-jobs';
            const nextName = '.carousel__next-jobs';
            const prevName = '.carousel__prev-jobs';

            state.swiper = new Swiper(swiperName, {
                loop: false,
                slidesPerView: 'auto',
                allowTouchMove: true,

                pagination: {
                    el: '.swiper-pagination',
                    type: 'progressbar'
                },
                navigation: {
                    nextEl: nextName,
                    prevEl: prevName
                },
                spaceBetween: 147, // 83 + 64, 83 is the width of the white background offset
                breakpoints: {
                    // when window width is >= 1024px
                    1024: {
                        spaceBetween: 147
                    },
                    800: {
                        spaceBetween: 147,
                        slidesPerView: 'auto'
                    },
                    641: {
                        spaceBetween: 40,
                        slidesPerView: 2
                    },
                    // when window width is >= 320px
                    320: {
                        slidesPerView: 1,
                        spaceBetween: 129
                    }
                }
            });
        };

        initSwiper();
    }
}
