/**
 * Accordion module file.
 */
import { gsap } from "gsap";

export default function () {
  const accordionLines = document.querySelectorAll(".js-accordion-parent");

  if (accordionLines) {
    const initAccordion = () => {
      accordionLines.forEach((line) => {
        const question = line.querySelector(".js-accordion-trigger");
        const answer = line.querySelector(".js-accordion-answer");

        question.addEventListener("click", (e) => {
          const isNotClickable = e.target.classList.contains(
            "js-accordion-prevent"
          );
          if (isNotClickable) {
            return;
          }

          if (answer && line) {
            if (line.classList.contains("js-accordion-line--opened")) {
              gsap.to(answer, {
                height: 0,
                opacity: 0,
                ease: "expo.out",
              });
            } else {
              gsap.to(answer, {
                height: "auto",
                opacity: 100,
                ease: "expo.out",
              });
            }
            const button = line.querySelector(".js-accordion-button");

            /**
             * If you ever need to change the orientation, this is the code that does it.
             * This code is actually for the + sign that goes to - when opened.
             *
             * The ? operand is a check to see if an element with the class vertical-line is present.
             *
             * If you have a chevron, you can add another line with the logic, or change line 45
             * to better represent the actual behavior.
             */
            if (button) {
              button
                .querySelector(".vertical-line")
                .classList.toggle("rotate-90");
            }

            line.classList.toggle("js-accordion-line--opened");
          }
        });
      });
    };
    initAccordion();
  }
}
