import Swiper, {
    Navigation,
    Pagination,
    Mousewheel
}
    from 'swiper';

export default function () {
    if (document.querySelector('.carousel-to-watch')) {
        Swiper.use([Navigation, Pagination, Mousewheel]);

        const state = {
            swiper: null
        };

        const initSwiper = () => {
            const swiperName = '.swiper-to-watch';
            const nextName = '.carousel__next-to-watch';
            const prevName = '.carousel__prev-to-watch';
            state.swiper = new Swiper(swiperName, {
                loop: false,
                slidesPerView: 'auto',
                direction: 'vertical',
                mousewheel: true,
                pagination: {
                    el: '.swiper-pagination-to-watch',
                    type: 'progressbar'
                },
                navigation: {
                    nextEl: nextName,
                    prevEl: prevName
                },
                breakpoints: {
                    641: {
                        direction: 'vertical'
                    },
                    320: {
                        direction: 'horizontal'
                    }
                }

            });
        };

        initSwiper();
    }

    const toWatchRepeater = document.querySelector('.to-watch__repeater');

    if (toWatchRepeater) {
        toWatchRepeater.addEventListener('mousemove', removeFade);

        function removeFade () {
            const toWatch = document.querySelector('.to-watch');
            const scrollHeight = toWatchRepeater.scrollHeight;
            const offsetHeight = toWatchRepeater.offsetHeight;

            if (toWatchRepeater.style.transform.includes(scrollHeight - offsetHeight)) {
                toWatch.classList.add('to-watch--bottom');
            } else {
                toWatch.classList.remove('to-watch--bottom');
            }
        }
    }
}
