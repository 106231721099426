import Swiper, { Navigation, Pagination } from 'swiper';

export default function () {
    Swiper.use([Navigation, Pagination]);

    const adjustNavigationPosition = (swiper) => {
        if (window.matchMedia('(max-width: 800px)').matches) {
            const swiperWrapperHeight = swiper.$wrapperEl[0].clientHeight;
            const activeSlideHeight = swiper.slides[swiper.activeIndex].clientHeight;
    
            const marginTopDifference = swiperWrapperHeight - activeSlideHeight;
            const navigationContainer = document.querySelector('.comparative-tabs__container-md .comparative-tabs__navigation');
            
            if (navigationContainer) {
                if (marginTopDifference === 0) {
                    navigationContainer.style.marginTop = "40px";
                } else {
                    const updatedMarginTop = marginTopDifference - 40;
                    navigationContainer.style.marginTop = `-${updatedMarginTop}px`;
                }
            }
        }
    };

    const initSwiperThreeCols = (selector) => {
        const nextName = '.carousel__next';
        const prevName = '.carousel__prev';

        const swiper = new Swiper(selector, {
            loop: false,
            allowTouchMove: true,
            spaceBetween: 24,
            slidesPerView: 'auto',
            slidesPerGroup: 3,
            pagination: {
                el: '.swiper-pagination',
                type: 'progressbar'
            },
            navigation: {
                nextEl: nextName,
                prevEl: prevName
            },
            breakpoints: {
                1280: {
                    slidesPerView: 3,
                    slidesPerGroup: 3
                },
                801: {
                    slidesPerGroup: 2,
                    slidesPerView: 2
                },
                320: {
                    slidesPerGroup: 1,
                    slidesPerView: 1
                }
            },
            on: {
                slideChange: function () {
                    adjustNavigationPosition(this);
                },
                init: function () {
                    adjustNavigationPosition(this);
                },
                resize: function () {
                    adjustNavigationPosition(this);
                },
            }
        });
    };

    const initSwiperTwoCols = (selector) => {
        const nextName = '.carousel__next';
        const prevName = '.carousel__prev';

        const swiper = new Swiper(selector, {
            loop: false,
            allowTouchMove: true,
            spaceBetween: 24,
            slidesPerView: 'auto',
            slidesPerGroup: 2,
            pagination: {
                el: '.swiper-pagination',
                type: 'progressbar'
            },
            navigation: {
                nextEl: nextName,
                prevEl: prevName
            },
            breakpoints: {
                801: {
                    slidesPerGroup: 2,
                    slidesPerView: 2
                },
                320: {
                    slidesPerGroup: 1,
                    slidesPerView: 1
                }
            },
            on: {
                slideChange: function () {
                    adjustNavigationPosition(this);
                },
                init: function () {
                    adjustNavigationPosition(this);
                },
                resize: function () {
                    adjustNavigationPosition(this);
                },
            }
        });
    };

    if (document.querySelector('.tabs--programs')) {
        setTimeout(setSelector, 500);

        function setSelector() {
            if (window.matchMedia('(max-width: 1280px)').matches) {
                if (window.matchMedia('(max-width: 800px)').matches) {
                    const selectorMobile = document.querySelector('.tabs .comparative-tabs__container-md .swiper-comparative-tabs');
                    initSwiperThreeCols(selectorMobile);
                } else {
                    const selectorMobile = document.querySelector('.tabs .swiper-comparative-tabs');
                    initSwiperThreeCols(selectorMobile);
                }
            } else {
                const selectorThreeCols = document.querySelector('.comparative-tabs__container.three-cols .swiper-comparative-tabs');
                const selectorTwoCols = document.querySelector('.comparative-tabs__container.two-cols .swiper-comparative-tabs');

                if (selectorThreeCols) {
                    initSwiperThreeCols(selectorThreeCols);
                }
                if (selectorTwoCols) {
                    initSwiperTwoCols(selectorTwoCols);
                }
            }
        }
    }
}