import Swiper, { Navigation, Pagination, Thumbs, Controller, EffectFade } from 'swiper';

export default function () {
    Swiper.use([Navigation, Thumbs, Controller, Pagination, EffectFade]);

    const initSwiper = () => {
        const nextName = '.carousel__next';
        const prevName = '.carousel__prev';

        const swiper = new Swiper('.swiper-text-image', {
            loop: false,
            effect: 'fade',
            allowTouchMove: true,
            pagination: {
                el: '.swiper-pagination',
                type: 'fraction'
            },
            navigation: {
                nextEl: nextName,
                prevEl: prevName
            }
        });
    };

    if (document.querySelector('.text-image')) {
        initSwiper();
    }
}
