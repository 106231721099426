/* eslint-disable indent */
import { gsap } from 'gsap';
/**
 * Tabs module file.
 */

export default function () {
    const tabs = document.querySelectorAll('.tab:not(.menu-item)');
    const sections = document.querySelectorAll('[data-tab]');
    const breakpoint = 1280;

    if (tabs.length) {
        if (document.body.offsetWidth > breakpoint) {
            tabs[0].classList.add('tab--active');
        }

        // activate first block(s)
        const listOfFirstAnchorIds = tabs[0].getAttribute('data-anchor-ids');

        if (document.body.offsetWidth > breakpoint) {
            sections.forEach((section) => {
                section.classList.add('section--disabled');

                if (listOfFirstAnchorIds.includes(section.getAttribute('data-anchor-id'))) {
                    section.classList.remove('section--disabled');
                }
            });
        }

        tabs.forEach((tab) => {
            const tabText = tab.querySelector('.tab__text');
            tabText.addEventListener('click', function (e) {
                const listOfAnchorIds = tab.getAttribute('data-anchor-ids');

                if (document.body.offsetWidth > breakpoint) {
                    tabs.forEach((tab) => {
                        tab.classList.remove('tab--active');
                    });
                    tab.classList.add('tab--active');

                    sections.forEach((section) => {
                        if (document.body.offsetWidth > breakpoint) {
                            section.classList.add('section--disabled');
                        }

                        if (listOfAnchorIds.includes(section.getAttribute('data-anchor-id'))) {
                            section.classList.remove('section--disabled');
                        }
                    });
                } else {
                    if (tab.classList.contains('mobile-tab--active')) {
                        gsap.to(tab.querySelector('.tab-container'), {
                            height: 0,
                            opacity: 0,
                            duration: 0.4,
                            ease: 'expo.out',
                        });
                    } else {
                        gsap.to(tab.querySelector('.tab-container'), {
                            height: 'auto',
                            opacity: 100,
                            duration: 0.4,
                            ease: 'expo.out',
                        });
                    }

                    tab.classList.toggle('mobile-tab--active');
                }

                /* if (document.body.offsetWidth <= breakpoint) {
                    tab.classList.toggle('tab--active');
                } */
            });
        });
    }

    if (document.body.offsetWidth <= breakpoint) {
        appendSections();
    }

    window.onresize = function () {
        if (document.body.offsetWidth <= breakpoint) {
            appendSections();
        } else {
            const tabsContainer = document.querySelector('.tabs');
            const sectionsArray = Array.from(sections);
            sectionsArray.reverse().forEach((section) => {
                insertAfter(tabsContainer, section);
            });
            desactivateAllTabsAndActivateFirstTab();
        }
    };

    function appendSections() {
        tabs.forEach((tab) => {
            const accordeonBoxes = document.querySelectorAll('[data-anchor-ids]');
            sections.forEach((section) => {
                section.classList.remove('section--disabled');
                accordeonBoxes.forEach((accordeonBox) => {
                    const attribut = accordeonBox.getAttribute('data-anchor-ids');
                    const sectionAttribut = section.getAttribute('data-anchor-id');
                    const sectionExists = accordeonBox.querySelector('[data-anchor-id="' + sectionAttribut + '"]');

                    if (attribut.includes(sectionAttribut) && !sectionExists) {
                        const clone = section.cloneNode(true);
                        accordeonBox.querySelector('.tab-container').appendChild(clone);
                    }
                });
            });
        });
    }

    function desactivateAllTabsAndActivateFirstTab() {
        const allTabs = document.querySelectorAll('[data-anchor-ids]');
        const firstTab = document.querySelector('[data-anchor-ids]');

        if (allTabs.length) {
            allTabs.forEach((tab) => {
                tab.classList.remove('tab--active');
            });
        }

        if (firstTab) {
            firstTab.classList.add('tab--active');
        }
    }

    function insertAfter(referenceNode, newNode) {
        referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
    }
}
