/**
 * Filters
 */
import { gsap } from 'gsap';

export default function () {
    const programsFilter = document.querySelectorAll('.programs__filter');
    const checkboxContainers = document.querySelectorAll('.checkbox__container');
    const programContainer = document.querySelector('.programs__container');
    const carousselContainer = document.querySelector('.by-programs');
    const cardPrograms = document.querySelectorAll('.card--program');
    const programsSelectedFilters = document.querySelector('.programs__selected-filters');
    const filterReinitiateButton = document.querySelector('.filter__reinitiate');
    const filterBackButton = document.querySelector('.filter__back-button');
    const pageTaxonomy = document.querySelector('.tax-types');

    let selectedFilters = {
        types: [],
        interests: [],
        academicPath: []
    };

    let appended = false;

    if (programContainer) {
        programContainer.classList.add('hidden');
    }

    if (pageTaxonomy) {
        const tab = pageTaxonomy.querySelector('.tabs');
        const taxonomy = tab.getAttribute('data-taxonomy');
        window.history.pushState('', '', '?types=' + taxonomy + '&interest=all&academic-path=all');
    }

    // open and close dropdown
    if (programsFilter.length) {
        programsFilter.forEach((tab) => {
            const selectBox = tab.querySelector('.filter__select-box');
            const checkboxes = tab.querySelector('.filter__checkboxes');
            const multiselect = tab.querySelector('.filter__multiselect');
            let mouseLeftCheckboxes = true;
            let mouseLeftMultiselect = true;

            multiselect.addEventListener('mouseenter', function (e) {
                if (document.body.offsetWidth > 800) {
                    removeAllActiveClasses();
                    checkboxes.classList.add('filter__checkboxes--active');
                    selectBox.classList.add('active');
                    mouseLeftMultiselect = false;
                }
            });

            checkboxes.addEventListener('mouseenter', function (e) {
                if (document.body.offsetWidth > 800) {
                    mouseLeftCheckboxes = false;
                }
            });

            checkboxes.addEventListener('mouseleave', function (e) {
                mouseLeftCheckboxes = true;

                if (mouseLeftCheckboxes && mouseLeftMultiselect && document.body.offsetWidth > 800) {
                    removeAllActiveClasses();
                }
            });

            multiselect.addEventListener('mouseleave', function (e) {
                mouseLeftMultiselect = true;

                if (mouseLeftCheckboxes && mouseLeftMultiselect && document.body.offsetWidth > 800) {
                    removeAllActiveClasses();
                }
            });

            selectBox.addEventListener('click', function (e) {
                if (document.body.offsetWidth <= 800) {
                    if (checkboxes.classList.contains('filter__checkboxes--active')) {
                        checkboxes.classList.remove('filter__checkboxes--active');
                        selectBox.classList.remove('active');

                        gsap.to(checkboxes, {
                            height: 0,
                            duration: 0.6
                        });
                    } else {
                        checkboxes.classList.add('filter__checkboxes--active');
                        selectBox.classList.add('active');

                        gsap.to(checkboxes, {
                            height: 'auto',
                            duration: 0.6
                        });
                    }
                }
            });
        });
    }

    // if onload there are already some params in url
    if (!appended && _getUrlParams().has('types')) {
        appendCards();
        const urlParams = _getUrlParams();
        const values = urlParams.getAll('types');
        const interestValues = urlParams.getAll('interest');

        for (const value of values) {
            if (value !== 'all') {
                if (value.includes('+')) {
                    const multipleValues = value.split('+');
                    selectedFilters.types.push(...multipleValues);
                } else {
                    selectedFilters.types.push(value);
                }
            }
        }

        for (const interestValue of interestValues) {
            if (interestValue !== 'all') {
                if (interestValue.includes('+')) {
                    const multipleValues = interestValue.split('+');
                    selectedFilters.interests.push(...multipleValues);
                } else {
                    selectedFilters.interests.push(interestValue);
                }
            }
        }

        toggleHiddenClass();
        checkboxContainers.forEach((checkboxContainer) => {
            const input = checkboxContainer.querySelector('input');

            if (selectedFilters.types.includes(input.value) || selectedFilters.interests.includes(input.value) || selectedFilters.academicPath.includes(input.value)) {
                input.classList.add('checked');
            }
        });
        createActiveFiltersButtons();
        hideFiltersAndBackButton();
    }

    // reinitiate filters
    if (filterReinitiateButton) {
        filterReinitiateButton.addEventListener('click', function (e) {
            window.history.pushState('', '', '?types=all&interest=all&academic-path=all');
            selectedFilters = {
                types: '',
                interests: '',
                academicPath: ''
            };

            toggleHiddenClass();
            checkboxContainers.forEach((checkboxContainer) => {
                const input = checkboxContainer.querySelector('input');
                input.classList.remove('checked');
            });
            createActiveFiltersButtons();
            hideFiltersAndBackButton();
        });
    }

    // on checkbox click update card, filters, buttons, url
    if (checkboxContainers) {
        checkboxContainers.forEach((checkboxContainer) => {
            checkboxContainer.addEventListener('click', function (e) {
                e.preventDefault();
                const input = checkboxContainer.querySelector('input');

                if (!appended) {
                    window.history.pushState('', '', '?types=all&interest=all&academic-path=all');
                    appendCards();
                }

                updateFilterList(input);
                toggleHiddenClass();
                createActiveFiltersButtons();
            });
        });
    }

    function updateFilterList (input) {
        const urlParams = _getUrlParams();

        if (selectedFilters.types.includes(input.value) || selectedFilters.interests.includes(input.value) || selectedFilters.academicPath.includes(input.value)) {
            selectedFilters.types = selectedFilters.types.filter((value) => value !== input.value);
            selectedFilters.interests = selectedFilters.interests.filter((value) => value !== input.value);
            selectedFilters.academicPath = selectedFilters.academicPath.filter((value) => value !== input.value);
            input.classList.remove('checked');
            removeValueFromUrl(urlParams, input);
        } else {
            if (input.name === 'types') {
                selectedFilters.types.push(input.value);
            } else if (input.name === 'interest') {
                selectedFilters.interests.push(input.value);
            } else if (input.name === 'academic-path') {
                selectedFilters.academicPath.push(input.value);
            }

            input.classList.add('checked');
            addValueToUrl(urlParams, input);
        }

        hideFiltersAndBackButton();
        window.history.pushState('', '', '?' + urlParams.toString());
    }

    function toggleHiddenClass () {
        cardPrograms.forEach((cardProgram) => {
            const typeMatches = selectedFilters.types.length === 0 || selectedFilters.types.includes(cardProgram.dataset.type);

            const interests = JSON.parse(cardProgram.dataset.interest);
            const interestMatches = selectedFilters.interests.length === 0 || interests.some((interest) => selectedFilters.interests.includes(interest));

            const academicPaths = JSON.parse(cardProgram.dataset.academicPath);
            // change "some" to "every" if AND relationship
            const academicPathMatches = selectedFilters.academicPath.length === 0 || academicPaths.some((path) => selectedFilters.academicPath.includes(path));

            if (typeMatches && academicPathMatches && interestMatches) {
                cardProgram.classList.remove('hidden');
            } else {
                cardProgram.classList.add('hidden');
            }
        });
    }

    function _getUrlParams () {
        return new URLSearchParams(window.location.search);
    }

    function appendCards () {
        appended = true;
        programContainer.classList.remove('hidden');
        const programContainerRow = programContainer.querySelector('.programs__row');
        carousselContainer.classList.add('hidden');
        cardPrograms.forEach((cardProgram) => {
            programContainerRow.appendChild(cardProgram);
            cardProgram.classList.add('hidden');
        });
    }

    function createActiveFiltersButtons () {
        const filterButtons = document.querySelectorAll('.filter-button');

        if (filterButtons.length) {
            filterButtons.forEach((filterButton) => {
                filterButton.remove();
            });
        }

        checkboxContainers.forEach((checkboxContainer) => {
            const input = checkboxContainer.querySelector('input');

            if (input.classList.contains('checked')) {
                const filterDiv = document.createElement('div');
                const checkboxText = checkboxContainer.querySelector('p');
                const checkboxIcon = checkboxContainer.querySelector('.icon');
                const text = document.createTextNode(checkboxText.textContent);

                if (checkboxIcon) {
                    filterDiv.innerHTML = checkboxIcon.innerHTML;
                }

                filterDiv.appendChild(text);
                filterDiv.classList.add('cta--main', 'outline', 'arrow-bottom', 'filter-button');
                filterDiv.dataset.selectedFilter = input.value;
                programsSelectedFilters.appendChild(filterDiv);

                filterDiv.addEventListener('click', function (e) {
                    e.preventDefault();
                    checkboxContainers.forEach((checkboxContainer) => {
                        const input = checkboxContainer.querySelector('input');

                        if (input.value === filterDiv.getAttribute('data-selected-filter')) {
                            input.classList.remove('checked');
                            filterDiv.remove();
                            updateFilterList(input);
                            toggleHiddenClass();
                        }
                    });
                });
            }
        });
    }

    function removeValueFromUrl (urlParams, input) {
        if (urlParams.has(input.name) && urlParams.get(input.name).includes(input.value)) {
            let newValues = 'all';

            if (urlParams.get(input.name).includes('+')) {
                const prevValues = urlParams.get(input.name);

                if (prevValues.includes('+' + input.value) || prevValues.includes(input.value + '+')) {
                    newValues = prevValues.replace('+' + input.value, '');
                    newValues = prevValues.replace(input.value + '+', '');
                }
            }

            urlParams.set(input.name, newValues);
        }
    }

    function addValueToUrl (urlParams, input) {
        if (urlParams.has(input.name)) {
            if (urlParams.get(input.name) === 'all') {
                urlParams.set(input.name, input.value);
            } else {
                const prevValues = urlParams.get(input.name);
                urlParams.set(input.name, prevValues + '+' + input.value);
            }
        }
    }

    function removeAllActiveClasses () {
        document.querySelectorAll('.filter__select-box').forEach((selectBox) => {
            selectBox.classList.remove('active');
        });
        document.querySelectorAll('.filter__checkboxes').forEach((checkboxes) => {
            checkboxes.classList.remove('filter__checkboxes--active');
        });
    }

    function hideFiltersAndBackButton () {
        if (selectedFilters.types.length === 0 && selectedFilters.interests.length === 0 && selectedFilters.academicPath.length === 0) {
            programsSelectedFilters.classList.add('hidden');
            filterBackButton.classList.add('hidden');
            filterReinitiateButton.classList.add('hidden');
        } else {
            programsSelectedFilters.classList.remove('hidden');
            filterBackButton.classList.remove('hidden');
            filterReinitiateButton.classList.remove('hidden');
        }
    }
}
