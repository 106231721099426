/* global Node, Event */

import { gsap } from 'gsap';

export default function () {
    /**
     * _setSingleTaxFilter
     *
     * Handles adjusting the filter elements based on the state of the urlParams.
     * Useful when handlind popstate event.
     *
     * @param Node filter               The filter DOM Node
     * @param URLSearchParams urlParams The current url query divided in parameters
     */
    function _setSingleTaxFilter(filter, urlParams) {
        const filterLayout = filter.dataset.filterLayout;

        if (urlParams.has('single_tax')) {
            if (filterLayout === 'list') {
                _setActiveFilterList(document.querySelectorAll('.filter--single-tax .filter__item'), document.querySelector('.filter--single-tax [data-filter="' + urlParams.get('single_tax') + '"]'));
            } else if (filterLayout === 'select') {
                _setActiveFilterSelect(document.querySelector('.filter--single-tax .filter__trigger'), urlParams.get('single_tax'));
            }
        } else {
            if (filterLayout === 'list') {
                _setActiveFilterList(document.querySelectorAll('.filter--single-tax .filter__item'), document.querySelector('.filter--single-tax [data-filter="all"]'));
            } else if (filterLayout === 'select') {
                _setActiveFilterSelect(document.querySelector('.filter--single-tax .filter__trigger'), 'all');
            }
        }
    }

    /**
     * _setMultipleTaxFilter
     *
     * Handles adjusting the filter elements based on the state of the urlParams.
     * Useful when handlind popstate event.
     *
     * @param URLSearchParams urlParams The current url query divided in parameters
     */
    function _setMultipleTaxFilter(urlParams) {
        if (urlParams.has('multiple_terms')) {
            const taxonomies = urlParams.get('multiple_terms').split('-');

            taxonomies.forEach((taxonomy) => {
                const select = document.getElementById(taxonomy + '-filter');

                if (urlParams.has(taxonomy)) {
                    _setActiveFilterSelect(select, urlParams.get(taxonomy));
                } else {
                    _setActiveFilterSelect(select, 'all');
                }
            });
        } else {
            const triggers = document.querySelectorAll('.filter--multiple-tax .filter__trigger');

            for (let i = 0; i < triggers.length; i++) {
                _setActiveFilterSelect(triggers[i], 'all');
            }
        }
    }

    /**
     * initLoadMore
     *
     * Initialize the loadmore functionnality when the button is found
     */
    function initLoadMore() {
        const loadMoreButton = document.getElementById('load-more-button');

        if (loadMoreButton) {
            loadMoreButton.addEventListener('click', loadMorePosts);
        }
    }

    /**
     * loadMorePosts
     *
     * Triggers on the loadmore button click.
     */
    function loadMorePosts(e) {
        // Default data to send to the backend
        const extraData = {
            pagination: 2,
        };

        // The current url query parameters
        const urlParams = _getUrlParams();

        // update the current page if it's set in the url
        if (urlParams.has('pagination')) {
            extraData.pagination = parseInt(urlParams.get('pagination')) + 1;
        }

        makeTheCall(extraData);
    }

    /**
     * initFilters
     *
     * Search for filters and initialize them accordingly.
     */
    function initFilters() {
        const filters = document.querySelectorAll('.filter');

        _setContainerMinHeight();

        for (let i = 0; i < filters.length; i++) {
            const filterType = filters[i].dataset.filterType;

            if (filterType.indexOf('single-taxonomy') >= 0) {
                initSingleTaxonomyFilter(filters[i]);
            }

            if (filterType.indexOf('multiple-taxonomies') >= 0) {
                initMultipleTaxonomiesFilter(filters[i]);
            }

            /*
            Add other types of filters

            $$$$$$$\  $$\           $$\        $$\           $$\
            $$  __$$\ \__|          $$ |       $$ |          $$ |
            $$ |  $$ |$$\  $$$$$$\  $$$$$$$\ $$$$$$\         $$$$$$$\   $$$$$$\   $$$$$$\   $$$$$$\
            $$$$$$$  |$$ |$$  __$$\ $$  __$$\\_$$  _|        $$  __$$\ $$  __$$\ $$  __$$\ $$  __$$\
            $$  __$$< $$ |$$ /  $$ |$$ |  $$ | $$ |          $$ |  $$ |$$$$$$$$ |$$ |  \__|$$$$$$$$ |
            $$ |  $$ |$$ |$$ |  $$ |$$ |  $$ | $$ |$$\       $$ |  $$ |$$   ____|$$ |      $$   ____|
            $$ |  $$ |$$ |\$$$$$$$ |$$ |  $$ | \$$$$  |      $$ |  $$ |\$$$$$$$\ $$ |      \$$$$$$$\
            \__|  \__|\__| \____$$ |\__|  \__|  \____/       \__|  \__| \_______|\__|       \_______|
                        $$\   $$ |
                        \$$$$$$  |
                        \______/                                                                  */
        }
    }

    /**
     * initSingleTaxonomyFilter
     *
     * Add the appropriate event listeners depending on the filter layout (either list or select)
     */
    function initSingleTaxonomyFilter(filter) {
        const filterLayout = filter.dataset.filterLayout;
        const filterTriggers = filter.querySelectorAll('.filter__trigger');

        for (let i = 0; i < filterTriggers.length; i++) {
            if (filterLayout === 'list') {
                filterTriggers[i].addEventListener('click', filterBySingleTaxonomy);
                filterTriggers[i].taxonomy = filter.dataset.filterType.split('=')[1];
            } else if (filterLayout === 'select') {
                filterTriggers[i].addEventListener('change', filterBySingleTaxonomy);
                filterTriggers[i].taxonomy = filter.dataset.filterType.split('=')[1];
            }
        }
    }

    /**
     * initMultipleTaxonomiesFilter
     *
     * Add event listener for Multiple Taxononmy Filter
     */
    function initMultipleTaxonomiesFilter(filter) {
        const filterTriggers = filter.querySelectorAll('.filter__trigger');
        const taxonomies = filter.dataset.filterType.split('=')[1];

        for (let i = 0; i < filterTriggers.length; i++) {
            filterTriggers[i].addEventListener('change', filterByMultipleTaxonomies);
            filterTriggers[i].taxonomies = taxonomies;
        }
    }

    /**
     * filterBySingleTaxonomy
     *
     * Get the information needed to filter by single taxonomy and triggers the ajax event.
     */
    function filterBySingleTaxonomy(e) {
        e.preventDefault();

        // Prevent clicking on active filter
        if (e.currentTarget.classList.contains('active')) {
            return false;
        }

        const target = e.currentTarget;
        const tagName = target.tagName;
        const cardsContainer = document.getElementById('ajax-cards-container');
        const siblingTrigger = target.parentNode.parentNode.childNodes;
        const taxonomy = target.taxonomy;
        let term = null;

        if (tagName === 'A') {
            _setActiveFilterList(siblingTrigger, target);
            term = target.dataset.filter;
        } else if (tagName === 'SELECT') {
            term = target.value;
        }

        if (cardsContainer) {
            cardsContainer.classList.add('loading');
        }

        const extraData = {
            pagination: 1,
            single_tax: taxonomy,
            term: term,
        };

        _hideCurrentCards(function () {
            makeTheCall(extraData);
        });
    }

    /**
     * filterByMultipleTaxonomies
     *
     * Get the information needed to filter by multiple taxonomies and triggers the ajax event.
     */
    function filterByMultipleTaxonomies(e) {
        e.preventDefault();

        // Cancel filter when dynamically changing the value
        if (e.cancelable) {
            return false;
        }

        // Prevent clicking on active filter
        if (e.currentTarget.classList.contains('active')) {
            return false;
        }

        // Set the min-height for the animation;
        _setContainerMinHeight();

        const cardsContainer = document.getElementById('ajax-cards-container');
        const target = e.currentTarget;
        const filter = target.closest('form');
        const extraData = {
            pagination: 1,
            multiple_terms: target.taxonomies,
            relation: 'AND',
        };

        target.taxonomies.split('-').forEach((taxonomy) => {
            const select = document.getElementById(taxonomy + '-filter');
            extraData[taxonomy] = select.value;
        });

        if (filter.dataset.relation) {
            extraData.relation = filter.dataset.relation;
        }

        if (cardsContainer) {
            cardsContainer.classList.add('loading');
        }

        _hideCurrentCards(function () {
            makeTheCall(extraData);
        });
    }

    /**
     * makeTheCall
     *
     * Make the ajax call based on the provided data
     *
     * @param {Object} extraData The extraneous data depending on which filter is used.
     * @param {Function} succesCallback A succesCallback function to be called if the request is a success.
     */
    function makeTheCall(extraData, succesCallback) {
        const button = document.getElementById('load-more-button');
        const cardsContainer = document.getElementById('ajax-cards-container');
        const data = {
            action: 'loadmore',
            path: cardsContainer.dataset.path,
            post_type: cardsContainer.dataset.postType,
            posts_per_page: cardsContainer.dataset.postsPerPage,
            get_posts: cardsContainer.dataset.getPosts,
            pagination: 2, // the first page to load will, by default, be the second one
        };

        // override and / or add our extra data to the default data
        for (const key in extraData) {
            data[key] = extraData[key];
        }

        jQuery.ajax({
            // TODO use when site is launched url: document.location.origin + '/wp-admin/admin-ajax.php'
            url: window.location.origin + '/laflech/wp-admin/admin-ajax.php',
            data: data,
            type: 'GET',
            /**
             * Before sending the ajax request
             */
            beforeSend: function (xhr) {
                if (button) {
                    button.classList.add('loading');
                }
            },
            /**
             * On request sucess
             */
            success: function (response) {
                const cardsContainer = document.getElementById('ajax-cards-container');

                response = JSON.parse(response);
                cardsContainer.insertAdjacentHTML('beforeend', response.html);

                // Remove the button if no pages are available
                if (button) {
                    if (!response.has_more_pages) {
                        button.classList.add('hidden');
                    } else {
                        button.classList.remove('hidden');
                    }
                }

                _setContainerMinHeight();

                if (Object.prototype.hasOwnProperty.call(response, 'remaining_terms')) {
                    _disableUnavailableFilterOptions(response.remaining_terms, response.post_type);
                }

                // Animate newly loaded posts
                _animateGeneratedPosts(cardsContainer);

                // Update the query parameters
                _updateQueryParams(data);

                // If a succesCallback was passed call it now
                if (succesCallback) {
                    succesCallback();
                }
            },
            /**
             * On request error
             */
            error: function (error) {
                console.log('Error while making an ajax request with the following data:', data);
                console.error(error);
            },
            /**
             * On request completion
             */
            complete: function () {
                const cardsContainer = document.getElementById('ajax-cards-container');
                cardsContainer.style.minHeight = cardsContainer.offsetHeight + 'px';

                if (button) {
                    button.classList.remove('loading');
                }
            },
        });
    }

    /**
     * _getUrlParams
     *
     * Utility function to get current query parameters
     */
    function _getUrlParams() {
        return new URLSearchParams(window.location.search);
    }

    /**
     * _updateQueryParams
     *
     * Update the url query parameters based on the ajax call data.
     */
    function _updateQueryParams(data) {
        const urlParams = _getUrlParams();

        if (Object.prototype.hasOwnProperty.call(data, 'single_tax') && Object.prototype.hasOwnProperty.call(data, 'term')) {
            if (data.term !== 'all') {
                urlParams.set('single_tax', data.single_tax);
                urlParams.set('term', data.term);
            } else {
                urlParams.delete('single_tax');
                urlParams.delete('term');
            }
        }

        if (Object.prototype.hasOwnProperty.call(data, 'multiple_terms')) {
            const taxonomies = data.multiple_terms.split('-');
            let deletedTaxCounter = 0;

            urlParams.set('multiple_terms', data.multiple_terms);
            urlParams.set('relation', data.relation);

            taxonomies.forEach((taxonomy) => {
                if (Object.prototype.hasOwnProperty.call(data, taxonomy)) {
                    if (data[taxonomy] !== 'all') {
                        urlParams.set(taxonomy, data[taxonomy]);
                    } else {
                        urlParams.delete(taxonomy);
                        deletedTaxCounter += 1;
                    }
                }
            });

            if (deletedTaxCounter === taxonomies.length) {
                urlParams.delete('multiple_terms');
                urlParams.delete('relation');
            }
        }

        if (data.pagination > 1) {
            urlParams.set('pagination', data.pagination);
        } else {
            urlParams.delete('pagination');
        }

        if (!data.prevent_history_update) {
            window.history.pushState(Object.fromEntries(urlParams), null, '?' + urlParams.toString());
        }
    }

    /**
     * _setContainerMinHeight
     *
     * Set a min height on the items wrapper. Useful to prevent jigger when animating the cards.
     */
    function _setContainerMinHeight() {
        const cardsContainer = document.getElementById('ajax-cards-container');
        cardsContainer.style.minHeight = '0';
        cardsContainer.style.minHeight = cardsContainer.offsetHeight + 'px';
    }

    /**
     * _setActiveFilterList
     *
     * Remove the active class from all the filters and add the active class to a single element
     *
     * @param {Array} triggers Array of Elements reprensenting the filters trigger.
     * @param {Element} currentTrigger The trigger on which to add the active class.
     */
    function _setActiveFilterList(triggers, currentTrigger) {
        for (let i = 0; i < triggers.length; i++) {
            if (triggers[i].nodeType !== Node.TEXT_NODE) {
                triggers[i].querySelector('.filter__trigger').classList.remove('active');
            }
        }

        currentTrigger.classList.add('active');
    }

    /**
     * _setActiveFilterSelect
     *
     * Remove the active class from all the filters and add the active class to a single element
     *
     * @param {Element} trigger The select element.
     * @param {String} value The vaue to set active.
     */
    function _setActiveFilterSelect(trigger, value) {
        trigger.value = value;
        trigger.dispatchEvent(new Event('change', { cancelable: true }));
    }

    /**
     * _disableUnavailableFilterOptions
     *
     * Disable options based on filtered post remaining terms.
     *
     * @param {Object} remainingTerms Each key is a taxonomy with an array of remaning terms
     * @param {String} postType The current post type.
     */
    function _disableUnavailableFilterOptions(remainingTerms, postType) {
        const filter = document.querySelector('.filter--' + postType);

        if (filter) {
            const options = filter.querySelectorAll('option');

            // reset disabled state
            for (let i = 0; i < options.length; i++) {
                options[i].removeAttribute('disabled');
            }
        }

        // make sure remainingTerms is something we can work with
        if (remainingTerms && typeof remainingTerms === 'object') {
            Object.keys(remainingTerms).forEach((taxonomy) => {
                const select = document.getElementById(taxonomy + '-filter');
                const options = select.querySelectorAll('option');

                // make sure our taxonomy is an array of terms
                if (Array.isArray(remainingTerms[taxonomy])) {
                    // disable every option
                    for (let i = 0; i < options.length; i++) {
                        const option = options[i];

                        if (option.getAttribute('value') !== 'all') {
                            option.setAttribute('disabled', true);
                        }
                    }

                    // enable only available ones
                    remainingTerms[taxonomy].forEach((term) => {
                        filter.querySelector('[value="' + term + '"').removeAttribute('disabled');
                    });
                }
            });
        }
    }

    /**
     * _animateGeneratedPosts
     *
     * Utility function to animate the generated posts.
     *
     * @param {Element} cardsContainer The container where all the cards are located
     */
    function _animateGeneratedPosts(cardsContainer) {
        const posts = cardsContainer.querySelectorAll('.generated');

        gsap.fromTo(
            posts,
            {
                opacity: 0,
                y: 100,
            },
            {
                opacity: 1,
                y: 0,
                duration: 0.24,
                stagger: {
                    each: 0.12,
                    onComplete: function () {
                        this.targets()[0].classList.remove('generated');
                    },
                },
                onComplete: function () {
                    cardsContainer.classList.remove('loading');
                },
            }
        );
    }

    /**
     * _hideCurrentCards
     *
     * Hide all the current cards
     *
     * @param {Function} callback A function to call when the animation is completed
     */
    function _hideCurrentCards(callback) {
        const currentCards = document.querySelectorAll('#ajax-cards-container .card');

        // Hide all currrent cards simultaneously and remove them on complete
        gsap.to(currentCards, {
            opacity: 0,
            y: 100,
            duration: 0.24,
            onComplete() {
                for (let i = 0; i < currentCards.length; i++) {
                    currentCards[i].remove();
                }

                if (callback) {
                    callback();
                }
            },
        });
    }

    /**
     * Init load more when the load more button is found
     */
    if (document.getElementById('load-more-button')) {
        initLoadMore();
    }

    /**
     * Init filters when the ajax filter is found
     */
    if (document.getElementById('ajax-filter')) {
        initFilters();
    }
}
