/**
 * Adjust the header alert bar styling on the body for layout and style purposes
 */

export default function () {
    adjustAlertBarHeight();
    let resizetimer = null;

    function adjustAlertBarHeight () {
        const alertBar = document.querySelector('.alert-bar');

        if (!alertBar) {
            document.body.style.setProperty('--alert-bar-h', '0px');
        } else {
            document.body.style.setProperty('--alert-bar-h', alertBar.offsetHeight + 'px');
        }
    }

    window.addEventListener('resize', () => {
        clearTimeout(resizetimer);
        resizetimer = window.setTimeout(() => {
            adjustAlertBarHeight();
        }, 250);
    });
}
