import { gsap } from 'gsap';

export default function () {
    /**
     * Get the .marquee elements and trigger the setup
     */
    function initMarquee () {
        const marquees = document.querySelectorAll('.marquee');

        if (marquees.length > 0) {
            for (let i = 0; i < marquees.length; i++) {
                setupTheMarquee(marquees[i]);
            }
        }
    }

    /**
     * Setup the marquee elements
     */
    function setupTheMarquee (marquee) {
        const marqueeImages = marquee.querySelectorAll('.marquee__images');

        if (marqueeImages.length > 0) {
            animation(marqueeImages, marquee);
            window.addEventListener('resize', () => { animation(marqueeImages, marquee); });
        }
    }

    function animation (marqueeImages, marquee) {
        marquee.setAttribute('style', '--marquee-width:' + '-' + marquee.offsetWidth + 'px;' + '--marquee-height:' + marqueeImages[0].offsetHeight + 'px;');
        gsap.set(marqueeImages, {
            left: (i) => i * marqueeImages[i].offsetWidth
        });
        gsap.to(marqueeImages, {
            duration: 40,
            ease: 'none',
            x: '-=' + marqueeImages[0].offsetWidth,
            repeat: -1
        });
    }

    if (document.querySelectorAll('.marquee')) {
        initMarquee();
    }
}
