/**
 * Accordeon module file.
 */
import { gsap } from 'gsap';

export default function () {
    function accordeon () {
        const accordeonCards = document.querySelectorAll('.accordeon__card');
        
        if (accordeonCards && accordeonCards.length > 0) {
            accordeonCards.forEach((card) => {
                const question = card.querySelector('.accordeon__question');

                question.addEventListener('click', function (e) {
                    e.preventDefault();
                    const currentCard = e.currentTarget.closest('.accordeon__card');
                    const answer = currentCard.querySelector('.accordeon__answer');

                    if (currentCard.classList.toggle('accordeon--active')) {
                        gsap.to(answer, {
                            height: 'auto',
                            duration: 0.4
                        });
                    } else {
                        gsap.to(answer, {
                            height: 0,
                            duration: 0.4
                        });
                    }
                });
            });
        }
    }

    toggleAccordeonClasses();
    accordeon();

    function toggleAccordeonClasses () {
        const nonClickable = [...document.getElementsByClassName('footer__menu-title')];

        if (nonClickable && nonClickable.length > 0) {
            nonClickable.forEach((card) => {
                const question = card.querySelector(':scope > a');
                const answer = card.querySelector('.sub-menu');

                adaptHeight(card, answer);

                window.addEventListener('resize', () => {
                    adaptHeight(card, answer);
                });

                question.addEventListener('click', function (e) {
                    e.preventDefault();

                    if (document.body.offsetWidth <= 800) {
                        if (card.classList.toggle('footer__menu-title--active')) {
                            gsap.to(answer, {
                                height: 'auto',
                                duration: 0.4
                            });
                        } else {
                            gsap.to(answer, {
                                height: 0,
                                duration: 0.4
                            });
                        }
                    }
                });
            });
        }
    }
}

function adaptHeight (card, answer) {
    if (document.body.offsetWidth <= 800) {
        answer.style.height = 0;
        card.classList.remove('footer__menu-title--active');
    } else {
        answer.style.height = 'auto';
    }
}
