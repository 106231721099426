/* eslint-disable indent */
import Swiper, { Navigation, Pagination } from 'swiper';

export default function () {
  if (document.querySelector('.swiper-carousel-video')) {
    Swiper.use([Navigation, Pagination]);

    const state = {
      swiper: null,
    };

    const initSwiper = () => {
      const swiperName = '.swiper-carousel-video';
      const nextName = '.swiper-button-next';
      const prevName = '.swiper-button-prev';

      state.swiper = new Swiper(swiperName, {
        loop: false,
        slidesPerView: 'auto',
        allowTouchMove: true,
        navigation: {
          nextEl: nextName,
          prevEl: prevName,
        },
        spaceBetween: 24,
      });
    };

    initSwiper();
  }
}
