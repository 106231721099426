/**
 * All code used to lock the screen
 */

export default class ScrollLock {
    constructor (ScrollLock) {
        this.html = document.querySelector('html');
        this.body = document.body;

        this.toggle = false;
        this.unlocked = true;
        this.scrollTop = -window.scrollY + 'px';
    }

    lock () {
        if (!this.html.classList.contains('noscroll')) {
            this.scrollTop = -window.scrollY + 'px';
            this.html.style.top = this.scrollTop;
            this.html.classList.add('noscroll');
        }
    }

    unlock () {
        const posFromTop = this.html.style.top;
        this.html.style.top = '';
        this.html.classList.remove('noscroll');
        this.html.scrollTop = Math.abs(parseInt(posFromTop));
    }

    toggleLock () {
        this.unlocked ? this.lock() : this.unlock();
        this.unlocked = !this.unlocked;
    }
}
