/**
 * Replace input file button
 */
export default function () {
    const inputFileContainers = document.querySelectorAll('.gfield .ginput_container_fileupload');

    inputFileContainers.forEach(inputFileContainer => {
        if (inputFileContainer) {
            const fileInput = inputFileContainer.querySelector('input[type="file"]');

            inputFileContainer.closest('.gfield').classList.add('gfield_file_upload');
            fileInput.addEventListener('change', onFileInputChange);

            setDataText(fileInput);
        }
    });

    function setDataText (fileInput) {
        const text = fileInput.getAttribute('data-select');
        fileInput.parentElement.setAttribute('data-select', text);
    }

    function onFileInputChange (e) {
        const filename = e.currentTarget[0].files[0].name;
        const fileNameElement = document.createElement('<span class="filename">' + filename + '</span>');
        fileNameElement.appendTo(e.currentTarget.closest('.ginput_container_fileupload'));
    }
}
