export default function () {
    function adjustHeights() {
        if (window.innerWidth >= 768) {
            const pathwayContainers = document.querySelectorAll('.card-regular .pathway__content');
            const listItems = document.querySelectorAll('.comparative-tabs__list .list-item');

            if (pathwayContainers.length === 0 || listItems.length === 0) return;

            const maxHeights = [];

            listItems.forEach((listItem, index) => {
                let maxHeight = 0;

                pathwayContainers.forEach((container) => {
                    const rows = container.querySelectorAll('.option_value');

                    if (rows[index]) {
                        rows[index].style.height = 'auto';
                        const rowHeight = rows[index].offsetHeight;

                        if (rowHeight > maxHeight) {
                            maxHeight = rowHeight;
                        }
                    }
                });

                pathwayContainers.forEach((container) => {
                    const rows = container.querySelectorAll('.option_value');

                    if (rows[index]) {
                        rows[index].style.height = `${maxHeight}px`;
                    }
                });

                listItem.style.height = `${maxHeight}px`;

                maxHeights.push(maxHeight);
            });

            const pathwayIntros = document.querySelectorAll('.card-regular .pathway__intro');
            let introMaxHeight = 0;

            pathwayIntros.forEach((element) => {
                element.style.height = 'auto';
                const elementHeight = element.offsetHeight;

                if (elementHeight > introMaxHeight) {
                    introMaxHeight = elementHeight;
                }
            });

            pathwayIntros.forEach((element) => {
                element.style.height = `${introMaxHeight}px`;
            });

            const comparativeTabsList = document.querySelector('.comparative-tabs__list');

            if (comparativeTabsList) {
                comparativeTabsList.style.marginTop = `${introMaxHeight}px`;
            }
        } else {
            const swiperSlides = document.querySelectorAll('.swiper-slide');

            swiperSlides.forEach((slide) => {
                const listItems = slide.querySelectorAll('.pathway__list .list-item');
                const optionValues = slide.querySelectorAll('.pathways__card-content .option_value');

                // Ensure the lists and their corresponding content have the same height
                listItems.forEach((listItem, index) => {
                    const correspondingOption = optionValues[index];

                    // Calculate the maximum height between the list item and the corresponding option value
                    const listItemHeight = listItem.offsetHeight;
                    const optionValueHeight = correspondingOption.offsetHeight;
                    const maxHeight = Math.max(listItemHeight, optionValueHeight);

                    // Apply the max height to both the list item and the corresponding option value
                    listItem.style.height = `${maxHeight}px`;
                    correspondingOption.style.height = `${maxHeight}px`;
                });
            });
        }
    }

    setTimeout(adjustHeights, 1000);

    window.addEventListener('resize', adjustHeights);
}
